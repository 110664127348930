import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";

export const Container = prop => {
  return (
    <Box
      sx={theme => ({
        maxWidth: {
          xs: "1280px",
          xxl: "1680px",
        },
        margin: "0 auto",
        width: "100%",
      })}
      px={{
        xs: 4,
        sm: 8,
        md: 8,
        lg: 10,
      }}
      py={{
        xs: 9,
        md: 10,
      }}
      {...prop}
    >
      {prop.children}
    </Box>
  );
};

export const GridContainer = prop => {
  return (
    <Grid
      container
      columnSpacing={{
        xs: 6,
        sm: 8,
        md: 8,
        lg: 12,
        xl: 12,
      }}
      alignItems="flex-start"
      {...prop}
    >
      {prop.children}
    </Grid>
  );
};
